import {LS_KEY} from "./constants";

const API_ROOT = 'https://dobry.ferrany.ru'
const API_ROOT_PROD = 'https://game.dobry-kids.ru'

export class ApiClient {
  static async post(url, data) {
    return await fetch(`${API_ROOT_PROD}${url}`, {
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
      method: "POST",
      mode: "cors",
      credentials: "omit"
    })
      .then(res => res.json())
      .catch(() => null);
  }

  static async loginAction(login = null, password = null) {
    let result = { error: true };
    const refresh = JSON.parse(localStorage.getItem(LS_KEY))?.refresh || null;

    if (!login && !password && !refresh) {
      return result;
    }

    const answer = await ApiClient.post('/users/v1/child/login', {
      login: login?.toLowerCase() || null,
      password: password?.toLowerCase() || null,
      refresh,
    });


    if (answer?.access) {
      result = answer;
      localStorage.setItem(LS_KEY, JSON.stringify(answer));
    } else if (answer?.code) {
      localStorage.removeItem(LS_KEY);
    }

    return result;
  }

  static async signUpAction(login, password, promo) {
    const answer = await ApiClient.post('/users/v1/child', {
      login: login.toLowerCase() || null,
      password: password?.toLowerCase() || null,
      promo_code: promo?.toLowerCase() || null,
    });
    let result = {error: true};

    if (answer?.access) {
      result = answer;
    }
    console.log(answer)
    return result;
  }

  static async validateUsername(username) {
    return await ApiClient.post(`/censorship/v1/validate?text=${username}`, {});
  }
}
