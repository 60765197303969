import React, {useEffect, useState} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import Loader from "./Loader/Loader";
import Login from "./Login/Login";
import {OrientationPlaceholder} from "./OrientationPlaceholder/OrientationPlaceholder";

const STATUSES = {
  GAME: 'GAME',
  LOGIN: 'LOGIN',
};

export default class UnityContainerComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <UnityContainer loginData={this.props.loginData} />
  }
}

export function UnityContainer(props) {
  const devUrl = "./build/";
  const url = devUrl;
  const {unityProvider, isLoaded, loadingProgression, sendMessage} = useUnityContext({
    loaderUrl: url + "dobry_test.loader.js",
    dataUrl: url + "dobry_test.data.unityweb",
    frameworkUrl: url + "dobry_test.framework.js.unityweb",
    codeUrl: url + "dobry_test.wasm.unityweb",
  });
  const [status, setStatus] = useState(STATUSES.LOGIN);
  const [loginData, setLoginData] = useState(props.loginData);

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        sendMessage(
          'UserManager',
          'SetNetworkUserData',
          JSON.stringify(loginData)
        )
      }, 1000);

    }
  }, [isLoaded]);

  function startUnityContainer(data) {
    setLoginData(data);
    setStatus(STATUSES.GAME);
  }

  function getContent() {
    if (!loginData && status === STATUSES.LOGIN) {
      return <Login onSuccess={(e) => startUnityContainer(e)}/>;
    }

    return <>
      {!isLoaded && <Loader progress={loadingProgression} isLoaded={isLoaded}/>}
      <Unity
        key={1}
        className="fill-window"
        unityProvider={unityProvider}
        devicePixelRatio={window.devicePixelRatio}
        style={{
          width: "100vw",
          height: window.innerHeight,
          overflow: "hidden",
          zIndex: 1
        }}
      />
    </>;
  }

  return <div className="fill-window">
    <OrientationPlaceholder />
    {getContent()}
  </div>
}
