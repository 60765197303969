import {ORIENTATION} from "./constants";
let isOrientationPermission = {
    requested: false,
    granted: false
}
let isOrientationPermissionGranted = false;
export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/Miui/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export function currentOrientation(){
    if(window.matchMedia("(orientation: portrait)").matches){
        return ORIENTATION.PORTRAIT;
    }
    return ORIENTATION.LANDSCAPE;
}
export function isOrientationPermissionRequested(){
    console.log()
    return isOrientationPermission.requested || !isMobile.any();
}
export async function getDeviceOrientationPermission(){
    isOrientationPermission.requested = true;
    if(isMobile.Android()) return true;
    if(isOrientationPermissionGranted) return true;
    if(DeviceOrientationEvent.requestPermission === undefined) return false;
    let response = await DeviceOrientationEvent.requestPermission();
    isOrientationPermission.granted = response === 'granted';
    return isOrientationPermission.granted;
}
export function isArAvailable(){
    if(!isMobile.any()) return false;
    if(isMobile.Android()) return true;
    return isOrientationPermission.granted;
}


export function getQuaternion( alpha, beta, gamma ) {
    let degtorad = Math.PI / 180;
    let _x = beta  ? beta * degtorad : 0;
    let _y = gamma ? gamma * degtorad : 0;
    let _z = alpha ? alpha * degtorad : 0;

    let cX = Math.cos( _x/2 );
    let cY = Math.cos( _y/2 );
    let cZ = Math.cos( _z/2 );
    let sX = Math.sin( _x/2 );
    let sY = Math.sin( _y/2 );
    let sZ = Math.sin( _z/2 );

    let w = cX * cY * cZ - sX * sY * sZ;
    let x = sX * cY * cZ - cX * sY * sZ;
    let y = cX * sY * cZ + sX * cY * sZ;
    let z = cX * cY * sZ + sX * sY * cZ;

    return [x, y, z, w];
}