/**
 * @typedef {Object} Rule
 * @property {string} name - The name of the CSS rule
 * @property {number} min - Value for 1366px screen
 * @property {number} max - Value for 1920px screen
 * @property {number[]} [clamp=undefined] - Min and max value for clamp()
 * */

/**
 * Converts style objects to css calc rules
 *
 * @param {Rule[]} rules
 * @returns {{}}
 */
export function setupCalc(rules) {
  const styles = {};
  rules.forEach((rule) => {
    const cssValue = `calc(${rule.min}px + (${rule.max} - ${rule.min}) * ((100vw - 375px) / 1545))`;
    styles[rule.name] = `clamp(${rule.min}px, ${cssValue}, ${rule.max}px)`;
  });

  return styles;
}
