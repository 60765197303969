import React from "react";
import "./Loader.css";
import {setupCalc} from "../../utils";
import char1 from "../../assets/char1.png";
import char2 from "../../assets/char2.png";

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: props.isLoaded
    }
  }

  render() {
    if (this.state.isDisabled) {
      return "";
    }
    return <div className="loader">
      <div className="loader-spinner-bg">
        <div className="loader-spinner-progress">

        </div>
        <div className="loader-spinner-title"></div>
      </div>
      <div className="loader-char-1" style={setupCalc([
        {name: 'width', min: 136, max: 387},
        {name: 'height', min: 164, max: 447},
        {name: 'left', min: 18, max: 176},
        {name: 'bottom', min: 82, max: 76},
      ])}>
        <img src={char1} alt="char1"/>
      </div>
      <div className="loader-char-2" style={setupCalc([
        {name: 'width', min: 146, max: 488},
        {name: 'height', min: 164, max: 450},
        {name: 'right', min: 18, max: 135},
        {name: 'bottom', min: 82, max: 91},
      ])}>
        <img src={char2} alt="char1"/>
      </div>
    </div>
  }
}
