import './App.css';
import UnityContainerComponent from "./components/UnityContainer";
import {useEffect, useState} from "react";
import {ApiClient} from "./apiClient";
import {LS_KEY} from "./constants";
import Loader from "./components/Loader/Loader";

function App() {
  const [loginData, setLoginData] = useState(null);
  const [canShow, setCanShow] = useState(false);

  useEffect(() => {
    // refresh token
    (async () => {
      const refresh = await ApiClient.loginAction();
      if (refresh?.access) {
        setLoginData(refresh);
      }

      setCanShow(true);
    })();
  }, []);

  return <>
    {
      canShow ? <UnityContainerComponent loginData={loginData}/> : <Loader />
    }
  </>;
}

export default App;
