export const ORIENTATION = {
    LANDSCAPE : "landscape",
    PORTRAIT : "portrait",
    BOTH : "both"
}

export const PLATFORMS = {
    MOBILE: "mobile",
    ANDROID: "android",
    IOS: "ios",
    DESKTOP: "desktop",
    ALL: "all"
}