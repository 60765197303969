import React, {Component} from "react";
import './Login.css';
import {ApiClient} from "../../apiClient";
import {setupCalc} from "../../utils";
import char1 from '../../assets/char1.png';
import char2 from '../../assets/char2.png';
import questionIcon from '../../assets/question.svg';
import logo from '../../assets/logo.png';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      hasError: false,
      isInvalid: false,
      showTooltip: false,
      passwordShown: false,
      login: '',
      promo: '',
      password: '',
      loginData: {},
    };
  }

  changeForm() {
    this.setState({
      isLogin: !this.state.isLogin,
      login: '',
      password: '',
      promo: '',
      hasError: false,
    });
  }

  async getLoginData(getLoginDataAction, login, password, promo = '') {
    this.setState({hasError: false});
    const loginData = await getLoginDataAction(login, password, promo);
    if (loginData.error) {
      this.setState({hasError: true});
      return null;
    }

    return loginData;
  }

  onSuccessAuth(loginData) {
    this.setState({loginData});
    this.props.onSuccess(loginData);
  }

  async checkIsValid(username) {
    let backendValid = false;
    const frontendValid = /\p{L}/gu.test(username) && !/@/.test(username) && username.length <= 12;

    if (frontendValid) {
      backendValid = await ApiClient.validateUsername(username);
    }

    return frontendValid && backendValid.valid;
  }

  async onSignUp(e) {
    e.target.blur();
    const isValid = await this.checkIsValid(this.state.login);

    if (!isValid) {
      this.setState({isInvalid: true})
      return;
    }

    const loginData = await this.getLoginData(
      ApiClient.signUpAction,
      this.state.login,
      this.state.password,
      this.state.promo
    );

    if (loginData) {
      this.onSuccessAuth(loginData);
    }
  }

  async onLogin(e) {
    e.target.blur();
    const loginData = await this.getLoginData(
      ApiClient.loginAction,
      this.state.login,
      this.state.password
    );
    if (loginData) {
      this.onSuccessAuth(loginData);
    }
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  }

  toggleTooltip() {
    if (this.state.showTooltip) {
      this.setState({showTooltip: false});
      return;
    }

    this.setState({showTooltip: true});

    setTimeout(() => {
      window.addEventListener('click', () => {
        this.setState({showTooltip: false});
      }, {once: true});
    }, 100);
  }

  getSignUpForm() {
    return <div className="form">
      <h1
        className="form__title"
        style={setupCalc([
          {name: 'fontSize', min: 30, max: 36},
          {name: 'marginBottom', min: 20, max: 40},
        ])}
      >
        Регистрация
      </h1>
      <div className="inputs" style={setupCalc([
        {name: 'gap', min: 10, max: 20},
        {name: 'marginBottom', min: 20, max: 40},
      ])}>
        <div>
          <input
            type="text"
            value={this.state.login}
            onFocus={() => this.setState({isInvalid: false})}
            onChange={(e) => this.setState({login: e.target.value})}
            id="login"
            placeholder="Никнейм"
            style={setupCalc([
              {name: 'fontSize', min: 24, max: 36},
              {name: 'height', min: 53, max: 80},
            ])}
          />
          {
            this.state.isInvalid
              ? <p className="error-text">Недопустимый никнейм</p>
              : ''
          }
        </div>
        <div style={{position: "relative"}}>
          <input
            type={this.state.passwordShown ? 'text' : 'password'}
            value={this.state.password}
            onChange={(e) => this.setState({password: e.target.value})}
            id="password"
            placeholder="пароль"
            style={setupCalc([
              {name: 'fontSize', min: 24, max: 36},
              {name: 'height', min: 53, max: 80},
            ])}
          />
          {
            this.state.password.length ? <p className='toggle' onClick={() => this.togglePassword()}>👁️</p> : ''
          }
        </div>
        <div className='promo-container'>
          <input
            type="text"
            value={this.state.promo}
            onChange={(e) => this.setState({promo: e.target.value})}
            id="promo"
            placeholder="промокод"
            style={setupCalc([
              {name: 'fontSize', min: 24, max: 36},
              {name: 'height', min: 53, max: 80},
            ])}
          />
          <img src={questionIcon} alt="?" onClick={() => this.toggleTooltip()}/>
          <div className={`popup ${this.state.showTooltip ? 'open' : ''}`}>
            <h3>Промокод</h3>
            <p>Возьми промокод у друга, <br/>чтобы вы вместе получили бонусы в игре</p>
          </div>
        </div>
      </div>
      <button
        onClick={(e) => this.onSignUp(e)}
        className="action"
        style={setupCalc([
          {name: 'fontSize', min: 20, max: 32},
          {name: 'height', min: 65, max: 100},
        ])}
      >
        <span>зарегистрироваться</span>
      </button>
      <button onClick={() => this.changeForm()} className="text mb">авторизация</button>
      <button className='text error' style={{color: this.state.hasError ? '#ffb100' : 'transparent'}}>
        Такой пользователь уже существует
      </button>
    </div>;
  }

  getLoginForm() {
    return <div className="form">
      <h1
        className="form__title"
        style={setupCalc([
          {name: 'fontSize', min: 30, max: 36},
          {name: 'marginBottom', min: 20, max: 40},
        ])}
      >
        Авторизация
      </h1>
      <div className="inputs" style={setupCalc([
        {name: 'gap', min: 10, max: 20},
        {name: 'marginBottom', min: 20, max: 40},
      ])}>
        <div>
          <input
            type="text"
            value={this.state.login}
            onChange={(e) => this.setState({login: e.target.value})}
            id="login"
            placeholder="Никнейм"
            style={setupCalc([
              {name: 'fontSize', min: 24, max: 36},
              {name: 'height', min: 53, max: 80},
            ])}
          />
        </div>
        <div style={{position: 'relative'}}>
          <input
            type={this.state.passwordShown ? 'text' : 'password'}
            value={this.state.password}
            onChange={(e) => this.setState({password: e.target.value})}
            id="password"
            placeholder="пароль"
            style={setupCalc([
              {name: 'fontSize', min: 24, max: 36},
              {name: 'height', min: 53, max: 80},
            ])}
          />
          {
            this.state.password.length ? <p className='toggle' onClick={() => this.togglePassword()}>👁️</p> : ''
          }
        </div>
      </div>
      <button
        onClick={(e) => this.onLogin(e)}
        className="action"
        style={setupCalc([
          {name: 'fontSize', min: 30, max: 48},
          {name: 'height', min: 65, max: 100},
        ])}
      >
        <span>Войти</span>
      </button>
      <button onClick={() => this.changeForm()} className="text mb">Регистрация</button>
      <button className='text error' style={{color: this.state.hasError ? '#ffb100' : 'transparent'}}>
        Неверный логин или пароль
      </button>
    </div>;
  }

  render() {
    return <div className="loader sign-up">
      <div className='logo' style={setupCalc([{
        name: 'paddingTop', min: 20, max: 40
      }])}>
        <img src={logo} alt="logo" style={setupCalc([
          { name: 'width', min: 300, max: 600 },
        ])}/>
      </div>

      <h2 className={'header-title'}>Построй Город вместе с друзьями</h2>

      {
        this.state.isLogin ? this.getLoginForm() : this.getSignUpForm()
      }
      <div className="loader-char-1" style={setupCalc([
        {name: 'width', min: 136, max: 387},
        {name: 'height', min: 164, max: 447},
        {name: 'left', min: 18, max: 176},
        {name: 'bottom', min: 82, max: 76},
      ])}>
        <img src={char1} alt="char1"/>
      </div>
      <div className="loader-char-2" style={setupCalc([
        {name: 'width', min: 146, max: 488},
        {name: 'height', min: 164, max: 450},
        {name: 'right', min: 18, max: 135},
        {name: 'bottom', min: 82, max: 91},
      ])}>
        <img src={char2} alt="char1"/>
      </div>
    </div>;
  }
}
